import { encryptdata } from "../utils/encrypt&decrypt";
import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

export const signIn = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signin", formData);
};
export const mentorsignIn = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/mentor-signin", formData);
};

export const signInsocial = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signin/google", formData);
};

export const checkUser = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/checkinfo", formData);
};

export const signUp = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signup", formData);
};
export const departmentList = (page, limit, query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/settings/department_list/?query=" +
      query +
      "&limit=" +
      limit +
      "&page=" +
      page
  );
};
export const collegeList = (page, limit, query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/settings/college_list/?query=" +
      query +
      "&limit=" +
      limit +
      "&page=" +
      page
  );
};
export const testList = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/question/test_list", Http.getAuthToken());
};
export const questionList = ({ department_id, question_group_id }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/question/question_list?department_id=" +
      department_id +
      "&question_group_id=" +
      question_group_id,
    Http.getAuthToken()
  );
};
export const answerAdd = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/question/answer_add",
    formData,
    Http.getAuthToken()
  );
};
export const answerList = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/question/answer_list?id=" + id,
    Http.getAuthToken()
  );
};
export const studentList = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/question/student_list", Http.getAuthToken());
};

export const profile = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user/my_account", Http.getAuthToken());
};

export const departmentUpdate = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/user/updatedepartment",
    formData,
    Http.getAuthToken()
  );
};

export const completedList = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/question/completed_list", Http.getAuthToken());
};

export const downloadReport = (student, college) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/user/download_report?student=" +
      student +
      "&college=" +
      college,
    Http.getAuthToken()
  );
};

export const hiring_partner_list = (query, limit, page) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/hiring_partner/?query=" +
      query +
      "&limit=" +
      limit +
      "&page=" +
      page,
    Http.getAuthToken()
  );
};

export const add_hiring_partner = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/hiring_partner/register",
    formData,
    Http.getAuthToken()
  );
};

export const get_hiring_partner = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/hiring_partner/byId" + id, Http.getAuthToken());
};

export const edit_hiring_partner = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/hiring_partner/update",
    formData,
    Http.getAuthToken()
  );
};

export const get_student_data = () => {
  console.log("HRFHJHJ");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/students/student_details/" + "temp",
    Http.getAuthToken()
  );
};

export const my_account_data = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user/my_account", Http.getAuthToken());
};

export const forgotPasswordMail = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/forgotPassword", formData);
};

export const resetPasswordByMail = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/forgotPasswordUpdate", formData);
};

export const createJobOpening = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/hiring_partner/create_job",
    formData,
    Http.getAuthToken()
  );
};

export const updateJobOpening = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/hiring_partner/update_job/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const ViewJobOpening = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/hiring_partner/view_job/" + formData,
    Http.getAuthToken()
  );
};

export const hiring_partner_profile = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/hiring_partner/profile", Http.getAuthToken());
};

export const profile_update_hiring_partner = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/hiring_partner/profile_update",
    formData,
    Http.getAuthToken()
  );
};

export const student_profile = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(formData, "student_profile_FORM");
  return Http.get(
    baseUrl + "/students/student_details/" + formData,
    Http.getAuthToken()
  );
};

export const profile_update_student = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/students/profile_update",
    formData,
    Http.getAuthToken()
  );
};

export const getCourseDetails = ({
  id,
  query,
  order,
  limit,
  sort,
  page,
  department_id,
}) => {
  console.log(
    id,
    "id",
    query + "query",
    order + "order",
    limit + "limit",
    sort + "sort",
    page + "page",
    department_id + "departmentapi"
  );
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/sprints/list?department_id=" +
      department_id +
      "&id=" +
      id +
      "&query=" +
      query +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const getPlanDetails = (
  planpage,
  planlimit,
  planquery,
  sortby,
  order,
  status,
  id,
  student_id,
  sprint_id,
  date,
  activity_id
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/studentassessment/list?student_id=" +
      student_id +
      "&date=" +
      date +
      "&status=" +
      status +
      "&sortby=" +
      sortby +
      "&sprint_id=" +
      sprint_id +
      "&query=" +
      planquery +
      "&page=" +
      planpage +
      "&limit=" +
      planlimit +
      "&order=" +
      order +
      "&activity_id=" +
      activity_id,
    Http.getAuthToken()
  );
};

export const getAllPlanDetails = (status, id, student_id, sprint_id, date) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/studentassessment/list?student_id=" +
      student_id +
      "&date=" +
      date +
      "&status=" +
      status +
      "&sprint_id=" +
      sprint_id,
    Http.getAuthToken()
  );
};

export const submitPlan = ({ formData }) => {
  const baseUrl = getBaseEndpointUrl();
  const payload = encryptdata(formData, "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)");
  return Http.post(
    baseUrl + "/studentassessment/addplan",
    { payload: payload },
    Http.getAuthToken()
  );
};

export const submitEOD = ({ formData }) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(formData, "API test err");

  // const payload = encryptdata(formData, "7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)")
  return Http.post(
    baseUrl + "/studentassessment/addeod",
    formData,
    Http.getFileHeader()
  );
};

export const college_list = (page, limit, query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/settings/college_list/?query=" +
      query +
      "&limit=" +
      limit +
      "&page=" +
      page
  );
};

export const mentor_list = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/mentor/list", Http.getAuthToken());
};

export const mentor_add = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/mentor/add", formData, Http.getAuthToken());
};

export const mentor_delete = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/mentor/delete", formData, Http.getAuthToken());
};

export const mentor_by_id = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/mentor/byId", formData, Http.getAuthToken());
};

export const mentor_edit = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl + "/mentor/update", formData, Http.getAuthToken());
};

export const getSprintStatus = (
  page,
  limit,
  query,
  sortby,
  order,
  status,
  id,
  student_id,
  sprint_id,
  activity_id
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/studentassessment/sprintstatus?student_id=" +
      student_id +
      "&sortby=" +
      sortby +
      "&sprint_id=" +
      sprint_id +
      "&query=" +
      query +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const getActivityList = ({
  page,
  limit,
  query,
  sortby,
  order,
  status,
  id,
  sprint_id,
}) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/sprints/activitylist?id=" +
      id +
      "&sortby=" +
      sortby +
      "&sprint_id=" +
      sprint_id +
      "&query=" +
      query +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const job_list = (query, limit, page, org_id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/hiring_partner/job_list/?query=" +
      query +
      "&limit=" +
      limit +
      "&page=" +
      page +
      "&org_id=" +
      org_id,
    Http.getAuthToken()
  );
};
export const updateStudentSprint = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/studentassessment/updatesprintstatus",
    formData?.formData,
    Http.getAuthToken()
  );
};

export const student_list = (query, limit, page, org_id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/students/list/?query=" +
      query +
      "&limit=" +
      limit +
      "&page=" +
      page +
      "&org_id=" +
      org_id,
    Http.getAuthToken()
  );
};

export const student_acceptance_status = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/students/update_acceptance_status",
    formData,
    Http.getAuthToken()
  );
};

export const project_list = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/hiringmentor/project_list", Http.getAuthToken());
};
export const getActivityTypes = (page, limit, query, type) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/studentassessment/activities/type?page=" +
      page +
      "&type=" +
      type +
      "&limit=" +
      limit,
    "&query=" + query,
    Http.getAuthToken()
  );
};

export const getEod = (
  eodpage,
  eodlimit,
  eodquery,
  sortby,
  order,
  activity_id,
  student_id,
  sprint_id
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/studentassessment/get-eod?student_id=" +
      student_id +
      "&activity_id=" +
      activity_id +
      "&sortby=" +
      sortby +
      "&sprint_id=" +
      sprint_id +
      "&query=" +
      eodquery +
      "&page=" +
      eodpage +
      "&limit=" +
      eodlimit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const getAllEod = (activity_id, student_id, sprint_id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/studentassessment/get-eod?student_id=" +
      student_id +
      "&sprint_id=" +
      sprint_id,
    Http.getAuthToken()
  );
};

export const hiringpartnerIn = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/hiring-partner-signin", formData);
};

export const registerPlacement = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/placement_register/create", formData);
};

export const getPlacementList = (page, limit, query, sortby, order) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/placement_register/list?query=" +
      query +
      "&sortby=" +
      sortby +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const ccMentorSignin = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/cc-mentor-signin", formData);
};

export const hiring_partner_dashboard = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/dashboard/hiringpartner", Http.getAuthToken());
};

export const admin_dashboard = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/dashboard/admindashboard", Http.getAuthToken());
};

export const principal_dashboard = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/dashboard/principaldashboard", Http.getAuthToken());
};

export const createProject = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/hiringmentor/projectadd",
    formData,
    Http.getAuthToken()
  );
};

export const updateProject = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/hiringmentor/update_project/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const ViewProject = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/hiringmentor/project_view/" + formData,
    Http.getAuthToken()
  );
};

export const student_by_id = (studentParam) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/students/student_by_id",
    studentParam,
    Http.getAuthToken()
  );
};

export const studentsByList = (page, limit, query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/students/studentsByList?page=" +
      page +
      "&limit=" +
      limit +
      "&query=" +
      query,
    Http.getAuthToken()
  );
};

export const studentsByProject = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/hiringmentor/project_assign_list/" + id,
    Http.getAuthToken()
  );
};
export const studentsassign = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/hiringmentor/project_assign/",
    formData,
    Http.getAuthToken()
  );
};

export const getfeedbackList = (
  feedbackpage,
  feedbacklimit,
  feedbackquery,
  student_id,
  sprint_id
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/cc_mentor/sprint_feedback_list/?student_id=" +
      student_id +
      "&sprint_id=" +
      sprint_id +
      "&query=" +
      feedbackquery +
      "&page=" +
      feedbackpage +
      "&limit=" +
      feedbacklimit,
    Http.getAuthToken()
  );
};

export const getccmentorStat = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/cc_mentor/get_stat/?id=" + id,
    Http.getAuthToken()
  );
};

export const updateSprintStatus = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/cc_mentor/create_sprint_feedback",
    formData,
    Http.getAuthToken()
  );
};

export const mentor_by_user_id = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/mentor/user_id", formData, Http.getAuthToken());
};

export const mentor_activity_list = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/hiringmentor/activity_list", Http.getAuthToken());
};

export const getProjectList = (payload) => {
  const { mentor_id, student_id, project_id } = payload;
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/students/fitment_project?student_id=" +
      student_id +
      "&mentor_id=" +
      mentor_id +
      "&project_id=" +
      project_id,
    Http.getAuthToken()
  );
};

export const postProjectPlan = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/students/create_project_plan",
    payload,
    Http.getAuthToken()
  );
};

export const postProjectEOD = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/students/create_project_eod",
    payload,
    Http.getFileHeader()
  );
};

export const getProjectPlan = (
  page,
  limit,
  query,
  sortby,
  order,
  status,
  student_id,
  project_id
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/students/project_plan_list?student_id=" +
      student_id +
      "&status=" +
      status +
      "&sortby=" +
      sortby +
      "&project_id=" +
      project_id +
      "&query=" +
      query +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const getProjectEOD = (
  page,
  limit,
  query,
  sortby,
  order,
  status,
  student_id,
  project_id
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/students/project_eod_list?student_id=" +
      student_id +
      "&status=" +
      status +
      "&sortby=" +
      sortby +
      "&project_id=" +
      project_id +
      "&query=" +
      query +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const getProjectFeedbackList = (
  page,
  limit,
  query,
  sortby,
  order,
  status,
  student_id,
  project_id
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/project/project_feedback_list?student_id=" +
      student_id +
      "&sortby=" +
      sortby +
      "&project_id=" +
      project_id +
      "&query=" +
      query +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const createfeedback = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/project/create_activity_feedback",
    formData,
    Http.getAuthToken()
  );
};

export const getSkills = (page, limit, query, sortby, order) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/settings/skills?query=" +
      query +
      "&sortby=" +
      sortby +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const overall_feedback = (
  page,
  limit,
  query,
  sortby,
  order,
  status,
  student_id
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/project/overall_ratings?student_id=" +
      student_id +
      "&sortby=" +
      sortby +
      "&query=" +
      query +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const overallSprintfeedback = (
  page,
  limit,
  query,
  sortby,
  order,
  status,
  student_id
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/sprints/overall_ranking?student_id=" +
      student_id +
      "&sortby=" +
      sortby +
      "&query=" +
      query +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const create_overall_feedback = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/hiringmentor/create_overall_feedback",
    formData,
    Http.getAuthToken()
  );
};

export const mentor_cc_feedbacks = (student_id) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(student_id, "mentor_cc_feedback");
  return Http.get(
    baseUrl + "/hiringmentor/mentor_cc_feedback?student_id=" + student_id,
    Http.getAuthToken()
  );
};

export const getEdu = (student_id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/students/get_student_edu?student_id=" + student_id,
    Http.getAuthToken()
  );
};

export const getCert = (student_id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/students/get_student_certificate?student_id=" + student_id,
    Http.getAuthToken()
  );
};

export const studentEdu = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/students/create_edu_details",
    formData,
    Http.getAuthToken()
  );
};

export const studentCertificates = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/students/create_certificate_details",
    formData,
    Http.getAuthToken()
  );
};

export const uploadVR = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/students/upload_vr",
    payload,
    Http.getFileHeader()
  );
};

export const adminsingIn = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/admin-signin", formData);
};

export const cc_mentor_list = (page, limit, query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/cc_mentor/list?page=" +
      page +
      "&limit=" +
      limit +
      "&query=" +
      query,
    Http.getAuthToken()
  );
};

export const cc_mentor_delete = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/cc_mentor/delete",
    formData,
    Http.getAuthToken()
  );
};

export const cc_mentor_add = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/cc_mentor/create",
    formData,
    Http.getAuthToken()
  );
};

export const cc_mentor_by_id = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/cc_mentor/byId", Http.getAuthToken());
};

export const cc_mentor_edit = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl + "/cc_mentor/update", formData, Http.getAuthToken());
};

export const admin_student_list = (
  page,
  limit,
  query,
  order,
  college_id,
  domain
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/admin/student_list?page=" +
      page +
      "&limit=" +
      limit +
      "&query=" +
      query +
      "&order=" +
      order +
      "&college_id=" +
      college_id +
      "&domain=" +
      domain,
    Http.getAuthToken()
  );
};

export const cc_mentor_assign_student = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/cc_mentor/mentor_assign_to_student",
    formData,
    Http.getAuthToken()
  );
};

export const getSessions = (
  page,
  limit,
  query,
  sortby,
  order,
  guest_speaker_id,
  domain
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/admin/sessions_list?guest_speaker_id=" +
      guest_speaker_id +
      "&domain=" +
      domain +
      "&query=" +
      query +
      "&sortby=" +
      sortby +
      "&page=" +
      page +
      "&limit=" +
      limit +
      "&order=" +
      order,
    Http.getAuthToken()
  );
};

export const getsettings = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/settings/settings", Http.getAuthToken());
};

export const registersession = (formData) => {
  console.log(formData, "FORMAPI");
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/students/register_session",
    formData,
    Http.getAuthToken()
  );
};

export const registeredsession = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/students/registered_session?student_id=" + formData,
    Http.getAuthToken()
  );
};
export const admin_student_career = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/admin/approve_student",
    formData,
    Http.getAuthToken()
  );
};

export const in_active_hp = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/hiring_partner/hiring_partner_inactive/",
    formData,
    Http.getAuthToken()
  );
};

export const assignstudent_hp = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/admin/assignstudent_hiringpartner/",
    formData,
    Http.getAuthToken()
  );
};

export const sprint_list = (page, limit, query, order, department_id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/sprints/list?page=" +
      page +
      "&limit=" +
      limit +
      "&query=" +
      query +
      "&order=" +
      order +
      "&department_id=" +
      department_id,
    Http.getAuthToken()
  );
};

export const domain_activity_list = (
  activitypage,
  activitylimit,
  activityquery,
  order,
  sprint_id,
  id
) => {
  console.log(activitypage, "TYPESEDfs");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/sprints/activitybyDomain?page=" +
      activitypage +
      "&limit=" +
      activitylimit +
      "&query=" +
      activityquery +
      "&order=" +
      order +
      "&id=" +
      id +
      "&sprint_id=" +
      sprint_id,
    Http.getAuthToken()
  );
};

export const domain_attachments_list = (
  attachmentpage,
  attachmentlimit,
  attachmentquery,
  order,
  activity_id,
  sprint_id,
  id
) => {
  console.log(attachmentpage, "attachmentpage1");
  const baseUrl = getBaseEndpointUrl();
  console.log(attachmentlimit, "attachmentlimit12");
  return Http.get(
    baseUrl +
      "/sprints/attachmentbyDomain?page=" +
      attachmentpage +
      "&limit=" +
      attachmentlimit +
      "&query=" +
      attachmentquery +
      "&order=" +
      order +
      "&id=" +
      id +
      "&activity_id=" +
      activity_id +
      "&sprint_id=" +
      sprint_id,
    Http.getAuthToken()
  );
};

export const create_sprint = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/sprints/create_sprint",
    formData,
    Http.getAuthToken()
  );
};

export const get_sprint_by_id = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/sprints/get_sprint_ById/" + id,
    {},
    Http.getAuthToken()
  );
};

export const update_sprint_data = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/sprints/update_sprint/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const create_activity = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/sprints/create_activity",
    formData,
    Http.getAuthToken()
  );
};

export const get_activity_by_id = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/sprints/get_activity_ById/" + id,
    {},
    Http.getAuthToken()
  );
};

export const update_activity_data = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/sprints/update_activity/" + id,
    formData,
    Http.getAuthToken()
  );
};
export const updatejobstatus = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/admin/update_job_status",
    formData,
    Http.getAuthToken()
  );
};

export const ccmentorStudents = (page, query, limit) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(page, "PAGEED");
  return Http.get(
    baseUrl +
      "/cc_mentor/cc_list?page=" +
      page +
      "&limit=" +
      limit +
      "&query=" +
      query,
    Http.getAuthToken()
  );
};

export const create_attachment = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/sprints/create_attachment",
    formData,
    Http.getAuthToken()
  );
};

export const get_attachment_by_id = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/sprints/get_attachment_ById/" + id,
    {},
    Http.getAuthToken()
  );
};
export const update_attachment_data = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/sprints/update_attachment/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const guestspeaker_list = (
  page,
  limit,
  query,
  order,
  domain,
  company_details
) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/admin/guest_speaker_list?page=" +
      page +
      "&limit=" +
      limit +
      "&query=" +
      query +
      "&order=" +
      order +
      "&domain=" +
      domain +
      "&company_details=" +
      company_details,
    Http.getAuthToken()
  );
};

export const guestspeaker_add = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/admin/create_guest_speaker",
    formData,
    Http.getFileHeader()
  );
};

export const guest_speaker_by_id = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/admin/guest_speaker/" + id,
    {},
    Http.getAuthToken()
  );
};

export const update_guest_speaker = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/admin/update_guest_speaker/" + id,
    formData,
    Http.getFileHeader()
  );
};

export const upload_sprint = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/sprints/sprint_bulkInsert/",
    formData,
    Http.getFileHeader()
  );
};

export const upload_activity = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/sprints/activity_bulkInsert/",
    formData,
    Http.getFileHeader()
  );
};

export const upload_attachment = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/sprints/attachment_bulkInsert/",
    formData,
    Http.getFileHeader()
  );
};

export const domain_create = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/settings/domain_create",
    formData,
    Http.getAuthToken()
  );
};

export const domain_by_id = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/settings/domain_by_id/" + id,
    {},
    Http.getAuthToken()
  );
};

export const domain_update = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/settings/update_domain/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const college_create = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/settings/college_create",
    formData,
    Http.getAuthToken()
  );
};

export const college_by_id = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/settings/college_by_id/" + id,
    {},
    Http.getAuthToken()
  );
};

export const college_update = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/settings/update_college/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const type_activity_create = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/settings/create_activity_type",
    formData,
    Http.getAuthToken()
  );
};

export const type_activity_by_id = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/settings/activity_type_by_id/" + id,
    {},
    Http.getAuthToken()
  );
};

export const type_activity_update = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/settings/update_activity_type/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const soft_skill_create = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/settings/create_skillset",
    formData,
    Http.getAuthToken()
  );
};

export const soft_skill_by_id = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/settings/soft_skill_by_id/" + id,
    {},
    Http.getAuthToken()
  );
};

export const soft_skill_update = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/settings/update_skillset/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const questions_by_department = (page, limit, query, department_id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/question/all_questions_list?page=" +
      page +
      "&limit=" +
      limit +
      "&query=" +
      query +
      "&department_id=" +
      department_id,
    Http.getAuthToken()
  );
};

export const create_questions = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/question/create/",
    formData,
    Http.getAuthToken()
  );
};

export const update_questions = (id, formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/question/update/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const questions_by_id = (id) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(id, "HGSFDHGFDGJHFD");
  return Http.get(baseUrl + "/question/by_id/" + id, Http.getAuthToken());
};

export const update_sessions = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(formData, "REGISTERUPDATE");
  return Http.post(
    baseUrl + "/students/update_registered_session/",
    formData,
    Http.getAuthToken()
  );
};

export const certificateData = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(formData, "formData1");
  return Http.post(
    baseUrl + "/certificate/view",
    formData,
    Http.getAuthToken()
  );
};

export const certificateUploadData = (formData)=>{
  const baseUrl = getBaseEndpointUrl();
  return Http.post (
    baseUrl + "/certificate/upload",
    formData,
    Http.getFileHeader()
  )
}
export const approve_student_sprint = (datas) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(datas, "formData1");
  return Http.post(
    baseUrl + "/admin/approveSprint",
    datas,
    Http.getAuthToken()
  );
};
