import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import CsLineIcons from "../cs-line-icons/CsLineIcons";
import { BsEye, BsActivity } from "react-icons/bs";
import {
  JobListData,
  hiringpartnerSelector,
  clearData,
} from "../../store/reducer/HiringPartnerReducer";
import {
  AdminDashboards,
  DashboardSelector,
  PrincipalDashboards,
  clearDataLoadings,
} from "../../store/reducer/DashboardReducer";
import {
  departmentLists,
  departmentUpdates,
  placementSelector,
  testLists,
} from "../../store/reducer/PlacementReducer";
import TablePagination from "@mui/material/TablePagination";
import dayjs from "dayjs";

const PrincipalDashboard = () => {
  const title = "Dashboard";
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState();
  useEffect(() => {
    let org_id = 0;
    dispatch(JobListData({ query, limit, page, org_id }));
    dispatch(PrincipalDashboards({}));
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    console.log(departmentQuery, "departmentQuery1");
    dispatch(departmentLists(departmentQuery));
  }, []);

  const { jobListDetails, hiringPartnerLoading, errorMessage } = useSelector(
    hiringpartnerSelector
  );
  const {
    admindashboardDatas,
    pdashboardDatas,
    hiringpartnerdashboardLoading,
    dashboarderrorMessage,
  } = useSelector(DashboardSelector);
  const { departmentData } = useSelector(placementSelector);
  useEffect(() => {
    console.log(jobListDetails, "jobListDetails");
    console.log(pdashboardDatas, "admindashboardDatas");
  }, [jobListDetails, pdashboardDatas]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = jobListDetails?.length % 10;
    let remainder = jobListDetails?.length / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [jobListDetails]);
  return (
    <div>
      {/* Total Counts of Student, Mentor and Jobs */}
      {/* <Row className="g-2">
        <Col sm="6" lg="3">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="building-large" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Total Colleges
              </div>
              <div className="text-small text-primary">
                <h3>{pdashboardDatas?.colleges_count}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="3">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="user" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Total Culture Catalyst(s)
              </div>
              <div className="text-small text-primary">
                <h3>{pdashboardDatas?.total_students_count}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="3">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="user" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Sprint Not Started
              </div>
              <div className="text-small text-primary">
                <h3>{pdashboardDatas?.ccmentor_count}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="6" lg="3">
          <Card className="hover-scale-up cursor-pointer sh-19">
            <Card.Body className="h-100 d-flex flex-column justify-content-between align-items-center">
              <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                <CsLineIcons icon="book" className="text-white" />
              </div>
              <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                Sprint Completed Students
              </div>
              <div className="text-small text-primary">
                <h3>{pdashboardDatas?.hiringpartner_count}</h3>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
      {/* Student, Mentor and Jobs Counts By Department */}
      <Row className="mx-2 mt-2">
        <Col md={12}>
          <Card className="mb-2 p-4">
            <Row className="h-100 align-content-center">
              <Col md="2" className="mb-2">
                <Card className="text-center p-2 m-1 shadow-lg bg-light">
                  Total Students Enrolled
                  <div className="text-primary text-center">
                    <h3>
                      {" "}
                      {pdashboardDatas?.allstudents
                        ? pdashboardDatas?.allstudents.length
                        : 0}{" "}
                      CC
                    </h3>
                  </div>
                </Card>
              </Col>
              {departmentData &&
                departmentData?.rows?.map((deparmentValues, index) => {
                  return (
                    <>
                      {pdashboardDatas?.student_by_domain?.[deparmentValues.id]
                        ?.student_count > 0 ? (
                        <Col md="2" className="mb-2">
                          <Card className="text-center p-2 m-1 shadow-lg bg-light">
                            {deparmentValues?.name}
                            <div className="text-primary text-center">
                              <h3>
                                {" "}
                                {pdashboardDatas?.student_by_domain?.[
                                  deparmentValues.id
                                ]?.student_count ?? 0}{" "}
                                CC
                              </h3>
                            </div>
                          </Card>
                        </Col>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </Row>
          </Card>
        </Col>
        <Col md={12}>
          {/* Jobs Details */}
          <Card body className="mb-5 mt-2">
            <Card.Title>
              <h3>Student Progress</h3>
            </Card.Title>
            {/* List Header Start */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Name</th>
                  <th>Domain</th>
                  <th>Sprint</th>
                  <th>Completed Activity</th>
                  <th>Status</th>
                  <th>Last Task Plan Updated</th>
                  <th>Last Task EOD Updated</th>
                  <th>View Progress</th>
                </tr>
              </thead>
              <tbody>
                {pdashboardDatas?.allstudents &&
                  pdashboardDatas?.allstudents?.map((jobValues, index) => {
                    let statusValue = pdashboardDatas?.studentLastUpdates?.find(
                      (ele) => {
                        return ele.student_id == jobValues?.id;
                      }
                    );
                    let pstatusValue =
                      pdashboardDatas?.studentPlanLastUpdates?.find((ele) => {
                        return ele.student_id == jobValues?.id;
                      });
                    let sstatusValue = pdashboardDatas?.student_count?.filter(
                      (ele) => {
                        return ele.student_id == jobValues?.id;
                      }
                    );
                    let estatusValue =
                      pdashboardDatas?.studentActivityCount?.filter((ele) => {
                        return ele.student_id == jobValues?.id;
                      });
                    let dstatusValue = departmentData?.rows?.find((ele) => {
                      return ele.id == jobValues?.department;
                    });

                    console.log(pdashboardDatas?.studentLastUpdates);
                    console.log(sstatusValue);
                    return (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{jobValues?.name}</td>
                          <td>{dstatusValue?.name}</td>
                          <td>{sstatusValue?.length}</td>
                          <td>{estatusValue?.length}</td>
                          <td>
                            {statusValue?.lastUpdate
                              ? "Started"
                              : <span style={{color:"red"}}>Yet to Start</span>}
                          </td>
                          <td>
                            {pstatusValue?.lastUpdate
                              ? dayjs(pstatusValue?.lastUpdate).format(
                                  "DD-MM-YYYY hh:mm a"
                                )
                              : "-"}
                          </td>
                          <td>
                            {statusValue?.lastUpdate
                              ? dayjs(statusValue?.lastUpdate).format(
                                  "DD-MM-YYYY hh:mm a"
                                )
                              : "-"}
                          </td>
                          <td>
                            <Link
                              title="View Sprint Progress"
                              className="btn btn-primary mx-1"
                              to={
                                "/hiring_partner/" +
                                jobValues?.id +
                                "/course/" +
                                jobValues?.department
                              }
                            >
                              <BsEye />
                            </Link>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
            {/* <div className="d-flex justify-content-end px-2">
              <TablePagination
                component="div"
                options={{
                  paging: false,
                }}
                count={jobListDetails?.count}
                page={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeRowsPerPage}
                pageSize={limit}
                rowsPerPageOptions={[limit]}
              />
            </div> */}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PrincipalDashboard;
