import { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkSprintStatus,
  clearCourseLoadings,
  courseDetails,
  courseSelector,
  updateSprint,
} from "../../store/reducer/CourseReducer";
import {
  approveStudentSprint,
  AdminSelector,
  clearAdminData,
} from "../../store/reducer/AdminReducer";
import {
  departmentLists,
  placementSelector,
} from "../../store/reducer/PlacementReducer";
import { API_STATUS, COURSE_STATUS } from "../../utils/Constants";
import {
  StudentById,
  StudentSelector,
} from "../../store/reducer/StudentReducer";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";

const StudentCourse = () => {
  const name = localStorage.getItem("username");
  const role = localStorage.getItem("role");
  const [course, setCourse] = useState({});
  const [order, setOrder] = useState("");
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [isNew, setIsNew] = useState();
  const [query, setQuery] = useState("");
  const [navSprint, setNavSprint] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, student_id } = useParams();
  const { departmentData } = useSelector(placementSelector);

  const { studentDetailsDatas } = useSelector(StudentSelector);
  const { approvestudentLoading } = useSelector(AdminSelector);
  const {
    courseData,
    sprintstatusDetails,
    updateSprintLoader,
    checkSprintStatusLoader,
  } = useSelector(courseSelector);
  console.log(sprintstatusDetails, "studentDetailsDatas course");
  const disPatchDatas = (id, student_id) => {
    if (id) {
      dispatch(
        courseDetails({
          department_id: id,
          id: "",
          order,
          limit,
          sort,
          page,
          query,
        })
      );
      dispatch(
        checkSprintStatus({
          page: 1,
          limit: "",
          query: "",
          sortby: "",
          order: "",
          status: "",
          student_id: student_id,
          sprint_id: "",
        })
      );
    }
  };
  useEffect(() => {
    disPatchDatas(id, student_id);
  }, [id, studentDetailsDatas]);

  useEffect(() => {
    const data = departmentData?.rows?.find((items) => {
      console.log(items.id, id, "DEPARTMENTED");
      return items.id == id;
    });

    setCourse(data);
  }, [departmentData]);

  useEffect(() => {
    let studentParam = {};
    studentParam.student_id = student_id;
    let departmentQuery = {};
    departmentQuery.page = 0;
    departmentQuery.limit = 0;
    departmentQuery.query = "";
    dispatch(departmentLists(departmentQuery));
    dispatch(StudentById({ studentParam }));
  }, []);

  useEffect(() => {
    if (sprintstatusDetails?.length == 0 && courseData?.count > 0) {
      setIsNew(0);
    }
  }, [sprintstatusDetails]);

  const handleSprintNavigate = (Sprintid) => {
    const formData = {
      sprint_id: Sprintid,
      domain: course?.id,
      activity_id: sprintstatusDetails[0]?.activity_id ?? 1,
      status: 0,
      student_id: studentDetailsDatas?.id,
    };
    dispatch(updateSprint({ formData }));
    setNavSprint(Sprintid);
  };

  const approveSprint = () => {
    const datas = {
      sprint_id: studentDetailsDatas?.current_sprint,
      domain: course?.id,
      student_id: student_id,
      status: 2,
    };
    dispatch(approveStudentSprint({ datas }));
  };

  useEffect(() => {
    if (updateSprintLoader === API_STATUS.FULFILLED) {
      navigate(
        `/hiring_partner/${student_id}/course/${id}/sprint/` + navSprint
      );
      dispatch(clearCourseLoadings());
    }
    if (checkSprintStatusLoader === API_STATUS.FULFILLED) {
      dispatch(clearCourseLoadings());
    }
    if (approvestudentLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Sprint Approved Successfully",
        icon: "success",
      });
      dispatch(clearCourseLoadings());
      dispatch(clearAdminData());
      disPatchDatas(id, student_id);
      let studentParam = {};
      studentParam.student_id = student_id;
      dispatch(StudentById({ studentParam }));
    }
  }, [updateSprintLoader, checkSprintStatusLoader, approvestudentLoading]);

  return (
    <div className="container-fluid mt-4">
      <div>
        <Row>
          <Col md={8}></Col>
          <Col md={4}>
            <div className="d-flex w-100 justify-content-end">
              <Button
                className="mb-3 mx-1"
                variant="success"
                onClick={(e) => {
                  name == "Principal"
                    ? navigate("/admin/pdashboard")
                    : role == 1
                    ? navigate("/admin/student_list")
                    : role == 2
                    ? navigate("/mentor/students")
                    : role == 5
                    ? navigate("/ccmentor/students")
                    : navigate("/student/list");
                }}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <Card>
          <div className="container">
            <Row className="mt-3">
              <Col md={6} className="pe-0 ps-5">
                <h1>
                  <label htmlFor="">
                    Culture Catalyst Name:{" "}
                    <strong>{studentDetailsDatas?.name}</strong>
                  </label>
                </h1>
              </Col>
              <Col md={6} className="text-end pe-5 ps-0">
                <h1>
                  Domain: <strong>{course?.name}</strong>
                </h1>
              </Col>
            </Row>
            <div className="container-fluid">
              <hr />
              <Row className="mt-3">
                <Col md={6} className="pe-0 ps-5">
                  <h3>
                    <strong>Total Sprints({courseData?.rows?.length})</strong>
                  </h3>
                </Col>
                {
                  name != "Principal"?
                <Col md={6} className="text-end pe-5 ps-0">
                  <Button
                    className="mb-3 mx-1"
                    variant="success"
                    onClick={() => {
                      approveSprint();
                    }}
                  >
                    Approve Sprint
                  </Button>
                </Col>
                :""}
              </Row>
              <div className="p-3">
                <Accordion defaultActiveKey="0">
                  {courseData?.rows?.map((sprints, i) => {
                    const stat = sprintstatusDetails.find((ele) => {
                      return ele.sprint_id == sprints?.id;
                    });
                    console.log(i, sprints, "SADFASDf");
                    const setStat =
                      isNew === i
                        ? COURSE_STATUS[0]
                        : COURSE_STATUS[stat?.status];
                    const sprintStatus = setStat ? setStat : "Not yet Started";
                    return (
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header className="w=100">
                          <div className="d-flex justify-content-between w-100">
                            <h6>{sprints?.name}</h6>
                            <div className="d-flex align-items-center">
                              <strong
                                className="me-3"
                                style={{ fontSize: "15px" }}
                              >
                                Duration : <span>{sprints.duration}</span>
                              </strong>
                              <LoadingButton
                                variant="contained"
                                loading={
                                  ![API_STATUS.FULFILLED, "initial"].includes(
                                    updateSprintLoader
                                  )
                                }
                                className="me-5"
                                onClick={() => {
                                  sprintStatus !== "Not yet Started" &&
                                    handleSprintNavigate(sprints?.id);
                                }}
                              >
                                <div style={{ fontSize: "15px" }}>
                                  {sprintStatus}
                                </div>
                              </LoadingButton>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <ListGroup>
                              {sprints?.sprintactivity?.map((activities) => {
                                return (
                                  <ListGroup.Item key={activities?.id}>
                                    <div className="d-flex justify-content-between w-100">
                                      <p>{activities?.name}</p>
                                      <p>{activities?.duration}</p>
                                    </div>
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default StudentCourse;
