import React, { useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { IoHome, IoIdCard, IoPerson, IoEaselSharp } from "react-icons/io5";
import { MdOutlineWork } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { MENU_BEHAVIOUR, MENU_PLACEMENT } from "../../../utils/Constants";
import {
  useWindowSize,
  checkBehaviour,
  checkPlacement,
  isDeeplyDiffBehaviourStatus,
  isDeeplyDiffPlacementStatus,
} from "../../../utils/config";
import {
  menuChangeAttrMenuAnimate,
  menuChangePlacementStatus,
  menuChangeCollapseAll,
  menuChangeBehaviourStatus,
  menuChangeAttrMobile,
  menuChangeNavClasses,
} from "../../../store/reducer/menuSlice";
import CsLineIcons from "../../cs-line-icons/CsLineIcons";
import { useNavigate, Link } from "react-router-dom";
import {
  userProfile,
  signInSelector,
  clearData,
} from "../../../store/reducer/SignInReducer";
import profile from "../../../assets/img/avatar.webp";
import { StudentSelector } from "../../../store/reducer/StudentReducer";
const DELAY = 80;

const Nav = () => {
  const name = localStorage.getItem("username");
  const role = localStorage.getItem("role");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    navClasses,
    placement,
    behaviour,
    placementStatus,
    behaviourStatus,
    attrMobile,
    breakpoints,
    useSidebar,
  } = useSelector((state) => state.menu);

  const { signInData, signInLoading, profileData, errorMessage } =
    useSelector(signInSelector);
  const { studentProfileDatas } = useSelector(StudentSelector);
  // console.log(
  //   studentProfileDatas,
  //   "SGFSDHFDJTKU"
  // );

  let studentStatus =
    studentProfileDatas?.studentData?.hiring_partner_student?.filter((ele) => {
      return ele.student_acceptance_status == 3;
    });
  let isShortlisted = studentStatus?.length != 0 ? 3 : 0;
  // studentProfileDatas?.studentData?.hiring_partner_student &&
  // studentProfileDatas?.studentData?.hiring_partner_student[0]
  //   ?.student_acceptance_status;

  const mouseActionTimer = useRef(null);
  const { width } = useWindowSize();
  const hideMobileMenu = () => {
    let newNavClasses = {
      ...navClasses,
      "mobile-side-out": true,
      "mobile-side-ready": true,
      "mobile-side-in": false,
    };
    dispatch(menuChangeNavClasses(newNavClasses));
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-side-ready": false,
        "mobile-side-out": false,
        "mobile-top-ready": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
    }, 200);
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-top-in": true,
        "mobile-top-ready": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
      dispatch(menuChangeAttrMobile(false));
    }, 230);
  };

  const getMenuStatus = useCallback(
    (pBreakpoints, pPlacement, pBehaviour) => {
      if (pBreakpoints) {
        const placementStatusCB = checkPlacement({
          placement: pPlacement,
          breakpoints: pBreakpoints,
        });
        const behaviourStatusCB = checkBehaviour({
          placement: placementStatusCB.placementHtmlData,
          behaviour: pBehaviour,
          breakpoints: pBreakpoints,
        });

        if (isDeeplyDiffPlacementStatus(placementStatusCB, placementStatus)) {
          dispatch(menuChangePlacementStatus(placementStatusCB));
        }
        if (isDeeplyDiffBehaviourStatus(behaviourStatusCB, behaviourStatus)) {
          dispatch(menuChangeBehaviourStatus(behaviourStatusCB));
        }
      }
      // eslint-disable-next-line
    },
    [behaviourStatus, placementStatus, breakpoints]
  );

  useEffect(() => {
    if (width && placement && behaviour && breakpoints) {
      getMenuStatus(breakpoints, placement, behaviour);
    }
    // eslint-disable-next-line
  }, [width, breakpoints, placement, behaviour]);

  useEffect(() => {
    if (navClasses && navClasses["mobile-side-in"]) {
      window.addEventListener("click", hideMobileMenu);
    }
    return () => {
      window.removeEventListener("click", hideMobileMenu);
    };
    // eslint-disable-next-line
  }, [navClasses]);
  // Starts mobile menu opening sequence
  const showMobileMenu = (e) => {
    e.preventDefault();
    dispatch(menuChangeAttrMobile(true));
    let newNavClasses = {
      ...navClasses,
      "mobile-top-out": true,
      "mobile-top-in": false,
      "mobile-top-ready": false,
    };
    dispatch(menuChangeNavClasses(newNavClasses));
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-top-out": false,
        "mobile-side-ready": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
    }, 200);
    setTimeout(() => {
      newNavClasses = {
        ...newNavClasses,
        "mobile-side-in": true,
      };
      dispatch(menuChangeNavClasses(newNavClasses));
    }, 230);
  };
  // Vertical menu semihidden state showing
  // Only works when the vertical menu is active and mobile menu closed
  const onMouseEnterDelay = () => {
    if (
      placementStatus.placementHtmlData === MENU_PLACEMENT.Vertical &&
      behaviourStatus.behaviourHtmlData === MENU_BEHAVIOUR.Unpinned &&
      attrMobile !== true
    ) {
      dispatch(menuChangeCollapseAll(false));
      dispatch(menuChangeAttrMenuAnimate("show"));
    }
  };

  // Delayed one that hides or shows the menu. It's required to prevent collapse animation getting stucked
  const onMouseEnter = () => {
    if (mouseActionTimer.current) clearTimeout(mouseActionTimer.current);

    mouseActionTimer.current = setTimeout(() => {
      onMouseEnterDelay();
    }, DELAY);
  };

  // Vertical menu semihidden state hiding
  // Only works when the vertical menu is active and mobile menu closed
  const onMouseLeaveDelay = () => {
    if (
      placementStatus.placementHtmlData === MENU_PLACEMENT.Vertical &&
      behaviourStatus.behaviourHtmlData === MENU_BEHAVIOUR.Unpinned &&
      attrMobile !== true
    ) {
      dispatch(menuChangeCollapseAll(true));
      dispatch(menuChangeAttrMenuAnimate("hidden"));
    }
  };

  const onMouseLeave = () => {
    if (mouseActionTimer.current) clearTimeout(mouseActionTimer.current);
    mouseActionTimer.current = setTimeout(() => {
      onMouseLeaveDelay();
    }, DELAY);
  };

  useEffect(() => {
    let formData = {};
    dispatch(userProfile({ formData }));
  }, []);

  useEffect(() => {
    console.log(profileData?.hpdatas?.certificate_status, "1123");
  }, [profileData]);

  return (
    <div
      id="nav"
      className={classNames("nav-container d-flex", navClasses)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="nav-content d-flex">
        <div className="logo position-relative">
          <Link
            to={
              role == 3
                ? "/student/profile"
                : role == 2
                ? "/mentor/profile"
                : role == 1
                ? "/admin/dashboard"
                : role == 5
                ? "/ccmentor/dashboard"
                : "/hiring_partner/profile"
            }
          >
            <div className="img"></div>
          </Link>
        </div>
        <div className="language-switch-container">
          <button
            className="btn btn-empty language-button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => {
              localStorage.clear();
              {
                role == 1
                  ? navigate("admin_signin")
                  : role == 2
                  ? navigate("/mentor_signin")
                  : role == 4
                  ? navigate("/hiring_partner_signin")
                  : role == 5
                  ? navigate("/ccmentor_signin")
                  : (window.location.href = "/");
              }
            }}
          >
            <CsLineIcons icon="logout" className="me-2" size="17" />{" "}
            <span className="align-middle">Logout</span>
          </button>
        </div>
        <div class="user-container d-flex">
          <Link
            to={
              role == 3
                ? "/student/profile"
                : role == 2
                ? "/mentor/profile"
                : role == 1
                ? "/admin/dashboard"
                : role == 5
                ? "/ccmentor/dashboard"
                : "/hiring_partner/profile"
            }
            class="d-flex user position-relative"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              className="profile"
              alt="profile"
              src={
                profileData?.studentdatas?.profile_image
                  ? profileData?.studentdatas?.profile_image
                  : profile
              }
            />
            <div class="name">{name}</div>
          </Link>
        </div>
        <div className="menu-container flex-grow-1 os-host os-theme-dark os-host-overflow os-host-overflow-x os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
          <div className="os-resize-observer-host observed">
            <div className="os-resize-observer"></div>
          </div>
          <div className="os-size-auto-observer observed">
            <div className="os-resize-observer"></div>
          </div>
          <div className="os-content-glue"></div>
          <div className="os-padding">
            <div className="os-viewport os-viewport-native-scrollbars-invisible">
              <div className="os-content">
                <ul id="menu" className="menu show">
                  <li>
                    <Link
                      to={
                        name == "Principal"?"/admin/pdashboard":role == 3
                          ? profileData?.studentdatas?.certificate_status == 1
                            ? "/certificate/view"
                            : "/student/dashboard"
                          : role == 2
                          ? "/mentor/dashboard"
                          : role == 1
                          ? "/admin/dashboard"
                          : role == 5
                          ? "/ccmentor/dashboard"
                          : profileData?.hpdatas?.certificate_status == 1
                          ? "/certificate/view"
                          : "/hiring_partner/dashboard"
                      }
                      className="d-flex justify-content-start align-items-center"
                    >
                      <IoHome />
                      <span className="label">
                        {(role == 3 &&
                          profileData?.studentdatas?.certificate_status == 1) ||
                        (role == 4 &&
                          profileData?.hpdatas?.certificate_status == 1)
                          ? "Certificate"
                          : "Dashboards"}
                      </span>
                      <MdNavigateNext />
                    </Link>
                  </li>
                  {role == 1 && name != "Principal" ? (
                    <>
                      <li>
                        <Link
                          to="/cc_mentor"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <IoPerson />
                          <span className="label">CC Mentor Management</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/admin/student_list"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <IoPerson />
                          <span className="label">Student Management</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/admin/hiring_partner"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <IoPerson />
                          <span className="label">
                            Hiring Partner Management
                          </span>
                          <MdNavigateNext />
                        </Link>
                        <Link
                          to="/admin/domain"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <CsLineIcons icon="list" className="text-white" />
                          <span className="label">Domain Management</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/admin/joblist"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <MdOutlineWork />
                          <span className="label">Job List</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/admin/guest_speaker"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <CsLineIcons icon="speaker" className="text-white" />
                          <span className="label">Guest Speaker</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/admin/college"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <CsLineIcons icon="building" className="text-white" />
                          <span className="label">College</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/admin/activity"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <CsLineIcons
                            icon="crosshair"
                            className="text-white"
                          />
                          <span className="label">Activity Type</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/admin/soft_skill"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <CsLineIcons icon="star" className="text-white" />
                          <span className="label">Soft Skill</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/admin/placement/list"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <CsLineIcons
                            icon="online-class"
                            className="text-white"
                          />
                          <span className="label">Placement Registered</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/admin/certificate"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <CsLineIcons icon="diploma" className="text-white" />
                          <span className="label">Certificate</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                    </>
                  ) : role == 4 &&
                    profileData?.hpdatas?.certificate_status == 0 ? (
                    <>
                      <li>
                        <Link
                          to="/student/list"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <IoPerson />
                          <span className="label">CC Management</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/hiring_partner/job"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <IoIdCard />
                          <span className="label">Job Management</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/mentor"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <IoPerson />
                          <span className="label">Mentor Management</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                    </>
                  ) : role == 2 ? (
                    <>
                      <li>
                        <Link
                          to="/mentor/students"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <IoIdCard />
                          <span className="label">Culture Catalyst</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/mentor/projectfitment"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <IoPerson />
                          <span className="label">Fitment Project</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                    </>
                  ) : role == 5 ? (
                    <>
                      <li>
                        <Link
                          to="/ccmentor/students"
                          className="d-flex justify-content-start align-items-center"
                        >
                          <IoIdCard />
                          <span className="label">Culture Catalyst</span>
                          <MdNavigateNext />
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      {studentProfileDatas?.studentData
                        ?.primarlimary_test_status === 2 ||
                      studentProfileDatas?.studentData
                        ?.primarlimary_test_status === 3 ? (
                        <>
                          <li>
                            <Link
                              to={`/student/course/${studentProfileDatas?.studentData?.department}`}
                              className="d-flex justify-content-start align-items-center"
                            >
                              <IoIdCard />
                              <span className="label">Career Journey</span>
                              <MdNavigateNext />
                            </Link>
                          </li>
                          {isShortlisted === 3 ? (
                            <>
                              {" "}
                              <li>
                                <Link
                                  to={`/student/projectfitment/`}
                                  className="d-flex justify-content-start align-items-center"
                                >
                                  <IoIdCard />
                                  <span className="label">Fitment Project</span>
                                  <MdNavigateNext />
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          <li>
                            <Link
                              to={`/student/sessions/`}
                              className="d-flex justify-content-start align-items-center"
                            >
                              <IoEaselSharp />
                              <span className="label">Sessions</span>
                              <MdNavigateNext />
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
            <div className="os-scrollbar-track os-scrollbar-track-off">
              <div className="os-scrollbar-handle"></div>
            </div>
          </div>
          <div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
            <div className="os-scrollbar-track os-scrollbar-track-off">
              <div className="os-scrollbar-handle"></div>
            </div>
          </div>
          <div className="os-scrollbar-corner"></div>
        </div>
        <div className="mobile-buttons-container">
          <a
            href="#"
            id="scrollSpyButton"
            className="spy-button"
            data-bs-toggle="dropdown"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="acorn-icons acorn-icons-menu-dropdown undefined"
            >
              <path d="M2 3 18 3M2 10 18 10M13 15 10.3536 17.6464C10.1583 17.8417 9.84171 17.8417 9.64645 17.6464L7 15"></path>
            </svg>
          </a>
          <div
            className="dropdown-menu dropdown-menu-end"
            id="scrollSpyDropdown"
          ></div>
          <a
            href="#"
            id="mobileMenuButton"
            className="menu-button"
            onClick={showMobileMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="acorn-icons acorn-icons-menu undefined"
            >
              <path d="M2 3 18 3M2 10 18 10M2 17 18 17"></path>
            </svg>
          </a>
        </div>
      </div>
      <div className="nav-shadow"></div>
    </div>
  );
};
export default Nav;
